::-webkit-input-placeholder {
  color: $color--grey--teaserborder;
  -webkit-text-fill-color: $color--grey--teaserborder;
}
::-moz-placeholder {
  color: $color--grey--teaserborder;
  -webkit-text-fill-color: $color--grey--teaserborder;
}
::-ms-placeholder {
  color: $color--grey--teaserborder;
  -webkit-text-fill-color: $color--grey--teaserborder;
}
::placeholder {
  color: $color--grey--teaserborder;
  -webkit-text-fill-color: $color--grey--teaserborder;
}

.input-el--autocomplete--is-loading {
  border-radius: $border-radius--default;
}

.input-el--has-value.input-el--is-valid input {
  border-color: $color--secondary;
}

.input-el {
  display: block;
  position: relative;
  input {
    border-color: $color--primary;
  }

  @include media-breakpoint-up('md') {
    margin: 0;
  }

  input {
    @extend %input-layout;

    box-shadow: none;

    .location-input--wrapper & {
      padding-left: 35px;
    }
  }

  &--label {
    font-weight: bold;
    min-width: auto;
    display: block;
    @include media-breakpoint-up('md') {
      min-width: auto;
    }
  }

  &--has-value,
  &--has-focus {
    .input-el--label {
      display: none;
    }
  }

  &--autocomplete--selected {
    input {
      font-weight: bold;
    }
  }

  &--feedback {
    display: none;
    color: $color--warn;
    position: absolute;
    line-height: 1.6;
    left: 0;
    right: 0;
    padding: 0;
    background-color: $color--white;
    z-index: 10;
    top: 0.8em; // positioning is correct at the content site
    font-style: italic;
    @include media-breakpoint-up(lg) {
      top: 0.7em; // positioning is correct at the content site
    }
  }

  &--warning-icon {
    line-height: 1;
    display: none;
    .input-el--has-feedback & {
      display: block;
    }
  }

  &--has-feedback {
    input {
      border-radius: 8px 8px 0 0;
    }

    .input-el--feedback {
      display: block;
    }
    .input-el--warning-icon {
      display: block;
    }
    .input-el--autocomplete--clear {
      display: none;
    }
    input {
      color: $color--warn;
      font-style: italic;
    }
    &.input-el--has-value {
      .input-el--feedback {
        display: none;
      }
    }
  }
}

.input-el--autocomplete {
  @include media-breakpoint-up('lg') {
    width: 50%;
    margin-right: math.div($grid-gutter-width, 2);
    margin-left: 0.5em;
  }

  input {
    position: relative;
  }

  label {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--icon-wrapper {
    width: 1.5em;
    height: 1em;

    .IconSmile {
      display: none;
      margin-left: -0.2em;
      margin-top: 0.2em;
    }
    .IconMarker {
      opacity: 0;
      @include media-breakpoint-up(lg) {
        opacity: 1;
      }
    }
  }

  &--is-loading {
    .IconSmile {
      display: block;
    }

    .IconMarker {
      display: none;
    }
  }

  &--clear {
    transition: opacity $default-transition-speed;
    opacity: 0;
    cursor: pointer;
    margin-left: 0.5em;
    .input-el--has-value & {
      opacity: 1;
    }

    svg {
      width: 12px;
      height: 12px;
      pointer-events: none;

      * {
        pointer-events: none;
        fill: $color--primary;
      }
    }
  }

  .autocomplete {
    position: absolute;
    top: 3em;
    left: -0.9em;
    right: -0.9em;
    z-index: 50;
    margin-top: 0;
    border-radius: 0 0 $border-radius--default $border-radius--default;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: $box-shadow--primary;
    display: none;

    @include media-breakpoint-up(md) {
      top: 3em;
    }

    & > div {
      display: block;
      background: $color--white;
      padding: 15px 1.4em;

      @include media-breakpoint-desk-and-up() {
        cursor: pointer;
        padding: 8px 15px 8px 35px;

        &:hover {
          background: $color--primary--light;
        }
      }

      &.selected {
        background: $color--grey--lighter;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.location_autocomplete_general_pickup {
      .autocomplete {
        left: -1.2em;
        right: -2.5em;
      }
    }
    &.location_autocomplete_general_delivery {
      margin-left: 0;
      .autocomplete {
        left: -1.4em;
        right: -1.6em;
      }
    }
  }

  .input-el--autocomplete--zero-result {
    position: absolute;
    z-index: 50;
    margin-top: 0;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: $box-shadow--primary;
    display: none;
    border: none;
    top: 3em;
    left: -0.9em;
    right: -0.9em;
    width: auto;
    padding: 15px 1.4em;
    border: none;
    background-color: #fff;
    border-radius: 0 0 $border-radius--default $border-radius--default;
    @include media-breakpoint-up(lg) {
      .location_autocomplete_general_pickup & {
        left: -1.2em;
        right: -2.5em;
      }
      .location_autocomplete_general_delivery & {
        left: -1.4em;
        right: -1.6em;
      }
    }
  }

  &.input-el--has-value {
    .autocomplete {
      display: block;
    }
  }

  &.input-el--show-zero,
  &.input-el--has-focus.input-el--has-feedback {
    .input-el--autocomplete--zero-result:not(:empty) {
      display: block;
    }
  }

  &--input-wrapper {
    display: flex;
    align-items: center;
    & > label {
      display: flex;
      flex-grow: 1;
      align-items: center;
      gap: 8px;
      input {
        flex-grow: 1;
      }
    }
  }
}
