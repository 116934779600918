@use 'sass:math';

@import 'helpers/all';

/**
* Only regular
*/
@import '../../node_modules/@brenger/react/fontFace';

body.main-nav--is-present {
  padding-top: 57px !important;

  @include media-breakpoint-up(lg) {
    padding-top: 86px !important;
  }
}

.brenger-menu--wrapper {
  * {
    font-family: $font-family--normal;
    box-sizing: border-box;
  }

  @import 'partials/button';
  @import 'menu/header';
  @import 'menu/navigation';
}
