.spinning-smile {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  position: relative;
  left: 0.6em;
  margin-right: 0.4em;
  animation: spin 0.33s infinite linear;
  transform-origin: 30% 50%;

  path {
    fill: $color--primary;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
      transform-origin: 30% 50%;
    }

    to {
      transform: rotate(360deg);
      transform-origin: 30% 50%;
    }
  }
}

.IconArrow .arrow-shape {
  fill: #fff;
}
