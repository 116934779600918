.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $color--white;
  z-index: 100;
  padding: math.div($grid-gutter-width, 2) 0;
  transition: all $default-transition-speed;

  .bd-progress-header-loaded & {
    display: none;
  }

  .container-fluid {
    padding: 0 math.div($grid-gutter-width, 2);
    max-width: $contentWidthLarge;
    margin: 0 auto;
  }

  .row {
    margin: 0 math.div($grid-gutter-width-negative, 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-list-col {
    position: relative;
    top: -28px;
    padding: 0 math.div($grid-gutter-width, 2);

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      top: 0;
    }
  }

  .logo-col {
    padding: 0 math.div($grid-gutter-width, 2);
    z-index: 15;
    line-height: 1;
    display: flex;
    align-items: flex-end;

    .brenger-logo {
      display: block;

      .cls-1 {
        fill: $color--primary;
        transition: all $default-transition-speed;
      }

      .main-nav--is-open & {
        .cls-1 {
          fill: $color--white;

          @include media-breakpoint-up(#{$menu-breakpoint}) {
            fill: $color--primary;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        height: 35px;
        width: 127px;
      }
    }
  }
}
