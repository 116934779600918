@use 'sass:math';

@import 'helpers/all';

/**
* Only regular
*/
@import '../../node_modules/@brenger/react/fontFace';

.brenger-tr-widget--wrapper {
  all: unset;
  font-size: 18px;
  label,
  div,
  span {
    all: unset;
    font-family: $font-family--normal;
  }

  div {
    display: block;
  }

  strong,
  .text--bold,
  .text--bold i {
    font-weight: bold;
  }

  * {
    font-family: $font-family--normal;
    box-sizing: border-box;
  }

  @import 'partials/all';
  @import 'widget/style';
  @import 'widget/stickyCta';
}
