.brenger-tr-widget {
  display: block;
  color: $color--black;
  background-color: #fff;
  border-radius: $border-radius--tile;
  padding: 0.9em;
  padding-top: 0.5em;
  box-shadow: 0px 8px 10px rgba(174, 191, 212, 0.2);
  margin-bottom: 0;
  position: relative;

  @include media-breakpoint-up('lg') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em;
  }

  svg.route {
    position: absolute;
    top: 1.9em;
    left: 1em;
    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  .destination-fields {
    @include media-breakpoint-up('md') {
      width: 100%;
      display: block;
    }
    @include media-breakpoint-up('lg') {
      display: flex;
      justify-content: space-between;
    }
  }

  &--divider {
    border-top: 0.5px solid #d8d8d8;
    margin-left: 26px;
    @include media-breakpoint-up('lg') {
      border-top: none;
      border-left: 0.5px solid #d8d8d8;
      margin: 0 1.35em;
    }
  }

  &--header {
    display: block;
    text-align: center;
    padding-bottom: math.div($grid-gutter-width, 4);
    font-weight: bold;
    font-size: 20px;
    color: $color--white;
    padding-top: math.div($grid-gutter-width, 2);
    padding-bottom: math.div($grid-gutter-width, 2);

    @include media-breakpoint-up('md') {
      width: 100%;
      text-align: left;
      font-size: 20px;
      padding-top: 0;
    }

    .theme--primary--light &,
    .hero--animated & {
      color: $color--primary;
    }
  }

  @include media-breakpoint-up(md) {
    .input-el--duplicate {
      display: none;
    }
  }
}

.brenger-autocomplete-widget {
  width: 100%;

  @include media-breakpoint-up(md) {
    box-sizing: border-box;
  }
  @include media-breakpoint-up(lg) {
    padding-right: $grid-gutter-width;
  }

  .input-el--autocomplete {
    width: 100%;
  }

  .brenger-autocomplete-overlay--mobile-header {
    display: none;
  }
}
