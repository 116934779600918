&.sticky-cta--wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px;
  z-index: 500;
  text-align: center;
  transition: 0.25s 0s transform;
  transform: translate3d(0, 110%, 0) scale(0.9);

  .show-sticky-cta & {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.btn {
  &--sticky-cta {
    width: auto;
    margin: math.div($grid-gutter-width, 4) auto;
  }
}
