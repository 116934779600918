//visiblility
@mixin visible($visible: true) {
  @if $visible {
    opacity: 1;
    visibility: visible;
  } @else {
    opacity: 0;
    visibility: hidden;
  }
}

@mixin media-breakpoint-desk-and-up() {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: #{$breakpoint-sm}) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (min-width: #{$breakpoint-md}) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media (min-width: #{$breakpoint-lg}) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media (min-width: #{$breakpoint-xl}) {
      @content;
    }
  }

  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == sm {
    @media (max-width: #{$breakpoint-sm}) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (max-width: #{$breakpoint-md}) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media (max-width: #{$breakpoint-lg}) {
      @content;
    }
  }

  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin labelStyle() {
  padding: 0 7px;
  border-radius: 100px;
  font-size: 0.55em;
  line-height: 1.9;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 0.5em;
}
