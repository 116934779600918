%container-layout {
  padding: 50px;
  text-align: center;
}

%input-layout {
  width: 100%;
  color: $color--black;
  background: $color--white;
  margin: 0;
  display: block;
  padding: 1em 0;
  border: none;
  font-size: inherit;
  @include media-breakpoint-up('lg') {
    padding: 0.75em 0;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    pointer-events: none;
    background: $color--blue--light;
  }

  &::-ms-clear {
    display: none;
  }

  label {
    margin: 0;
  }
}

%button {
  font-weight: bold;
  border-radius: $border-radius--default;
  -webkit-appearance: none;
  padding: 15px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 0.3em;
  font-size: inherit;
  line-height: inherit;

  @include media-breakpoint-up(md) {
    padding: $input-spacing;

    &:hover {
      text-decoration: none;
    }
  }
}
