@font-face {
  font-family: bariol;
  src: url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff2") format("woff2"), url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body.main-nav--is-present {
  padding-top: 57px !important;
}

@media (min-width: 992px) {
  body.main-nav--is-present {
    padding-top: 86px !important;
  }
}

.brenger-menu--wrapper * {
  box-sizing: border-box;
  font-family: bariol, Arial, sans-serif;
}

.brenger-menu--wrapper .input-el--button {
  width: 100%;
}

@media (min-width: 768px) {
  .brenger-menu--wrapper .input-el--button {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .brenger-menu--wrapper .input-el--button {
    margin-bottom: 0 !important;
  }
}

.brenger-menu--wrapper .btn {
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  width: 100%;
  padding: 11px 15px;
  text-decoration: none;
  display: inline-block;
}

.brenger-menu--wrapper .btn.widget {
  text-align: left;
  border-radius: 10px;
  padding: .85em;
  line-height: 1;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .btn.widget {
    width: auto;
    min-width: 240px;
  }
}

.brenger-menu--wrapper .btn--secondary {
  color: #fff;
  background: #00d896;
  border-color: #00d896;
}

@media (min-width: 768px) {
  .brenger-menu--wrapper .btn--secondary:hover {
    background: #00bd84;
    border-color: #00bd84;
  }
}

.brenger-menu--wrapper .btn--icon-right {
  position: relative;
}

.brenger-menu--wrapper .btn--icon-right .icon-wrapper svg, .brenger-menu--wrapper .btn--icon-right svg:not(.IconSmile) {
  width: 1.2em;
  height: 1.2em;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.brenger-tr-widget--wrapper .brenger-menu--wrapper .btn--icon-right .icon-wrapper svg, .brenger-tr-widget--wrapper .brenger-menu--wrapper .btn--icon-right svg:not(.IconSmile) {
  float: right;
  width: 1em;
  height: 1em;
  position: static;
  transform: none;
}

.brenger-menu--wrapper .btn--icon-right {
  padding-right: 40px;
}

.brenger-menu--wrapper .btn--icon-right .icon-wrapper svg, .brenger-menu--wrapper .btn--icon-right svg {
  right: 10px;
}

.brenger-menu--wrapper .main-header {
  z-index: 100;
  background: #fff;
  padding: 15px 0;
  transition: all .25s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.bd-progress-header-loaded .brenger-menu--wrapper .main-header {
  display: none;
}

.brenger-menu--wrapper .main-header .container-fluid {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 15px;
}

.brenger-menu--wrapper .main-header .row {
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
  display: flex;
}

.brenger-menu--wrapper .main-header .nav-list-col {
  padding: 0 15px;
  position: relative;
  top: -28px;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-header .nav-list-col {
    top: 0;
  }
}

.brenger-menu--wrapper .main-header .logo-col {
  z-index: 15;
  align-items: flex-end;
  padding: 0 15px;
  line-height: 1;
  display: flex;
}

.brenger-menu--wrapper .main-header .logo-col .brenger-logo {
  display: block;
}

.brenger-menu--wrapper .main-header .logo-col .brenger-logo .cls-1 {
  fill: #0073fa;
  transition: all .25s;
}

.main-nav--is-open .brenger-menu--wrapper .main-header .logo-col .brenger-logo .cls-1 {
  fill: #fff;
}

@media (min-width: 992px) {
  .main-nav--is-open .brenger-menu--wrapper .main-header .logo-col .brenger-logo .cls-1 {
    fill: #0073fa;
  }

  .brenger-menu--wrapper .main-header .logo-col .brenger-logo {
    width: 127px;
    height: 35px;
  }
}

.brenger-menu--wrapper .main-nav--toggle {
  z-index: 15;
  position: absolute;
  top: 18px;
  right: 15px;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--toggle {
    display: none;
  }
}

.brenger-menu--wrapper .main-nav--toggle-hamburger {
  opacity: 1;
  background: #0073fa;
  border-radius: 3px;
  width: 25px;
  height: 3px;
  margin: 0 0 4px;
  transition: all .25s;
  position: relative;
  transform: rotate(0)translate3d(0, 0, 0)scale(1);
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--toggle-hamburger {
  background: #fff;
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--toggle-hamburger:first-child {
  transform: rotate(-45deg)translate3d(-2px, 4px, 0)scale(.85);
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--toggle-hamburger:nth-child(2) {
  opacity: 0;
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--toggle-hamburger:nth-child(3) {
  transform: rotate(45deg)translate3d(-6px, -8px, 0)scale(.85);
}

.brenger-menu--wrapper .main-nav--filler {
  z-index: 1;
  background: #0073fa;
  border-radius: 50%;
  width: 320vw;
  height: 320vw;
  transition-delay: .25s;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(320vw, -160vw, 0);
}

.init .brenger-menu--wrapper .main-nav--filler {
  transition: all .5s;
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--filler {
  transition-delay: 0s;
  transform: translate3d(160vw, -40vw, 0);
}

@media (orientation: portrait) {
  .brenger-menu--wrapper .main-nav--filler {
    width: 250vh;
    height: 250vh;
    transform: translate3d(250vh, -125vh, 0);
  }

  .main-nav--is-open .brenger-menu--wrapper .main-nav--filler {
    transform: translate3d(125vh, -31.25vh, 0);
  }
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--filler {
    display: none;
  }
}

.brenger-menu--wrapper .main-nav--items {
  color: #fff;
  visibility: hidden;
  z-index: 10;
  padding-top: 75px;
  padding-bottom: 75px;
  transition: all .5s;
  position: fixed;
  inset: 0;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items {
    visibility: visible;
    margin-top: -2px;
    padding: 0;
    transition: none;
    position: relative;
    inset: auto;
  }
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--items {
  visibility: visible;
  opacity: 1;
  visibility: visible;
}

.brenger-menu--wrapper .main-nav--items > ul {
  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  list-style: none;
  position: relative;
  overflow: hidden auto;
  margin: 0 !important;
}

.brenger-menu--wrapper .main-nav--items > ul .nav-item--has-sub > .nav-item--label {
  display: none;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items > ul {
    float: right;
    align-items: center;
    width: auto;
    height: 3.2em;
    padding: 0;
    display: flex;
    overflow: visible;
  }

  .brenger-menu--wrapper .main-nav--items > ul .nav-item--has-sub > .nav-item--label {
    display: block;
  }

  .brenger-menu--wrapper .main-nav--items > ul.main-nav--items-mobile {
    display: none;
  }
}

.brenger-menu--wrapper .main-nav--items a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items a:hover {
    opacity: .75;
    text-decoration: none;
  }
}

.brenger-menu--wrapper .main-nav--items .bottom-position, .brenger-menu--wrapper .main-nav--items .nav-item--label {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .brenger-menu--wrapper .main-nav--items .bottom-position, .brenger-menu--wrapper .main-nav--items .nav-item--label {
    color: #0073fa;
    font-size: inherit;
  }
}

.brenger-menu--wrapper .main-nav--items li {
  opacity: 0;
  visibility: hidden;
  padding: .2em 0;
  transition: all .5s .25s;
  transform: translate3d(100px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-1 {
  transform: translate3d(130px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-2 {
  transform: translate3d(120px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-3 {
  transform: translate3d(110px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-4 {
  transform: translate3d(100px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-5 {
  transform: translate3d(110px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-6 {
  transform: translate3d(120px, 0, 0);
}

.brenger-menu--wrapper .main-nav--items li.menu-index-7 {
  transform: translate3d(130px, 0, 0);
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--items li {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items li {
    opacity: 1;
    visibility: visible;
    margin-right: 20px;
    padding: 0;
    transition: none;
    display: inline-block;
    transform: translate3d(0, 0, 0) !important;
  }

  .brenger-menu--wrapper .main-nav--items li a {
    color: #0073fa;
  }

  .brenger-menu--wrapper .main-nav--items li > a {
    display: inline-block;
  }
}

.brenger-menu--wrapper .main-nav--items li.hidden {
  display: none;
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items ul > li:last-child {
    margin-right: 0;
  }
}

.brenger-menu--wrapper .main-nav--items .sub-nav {
  margin-top: 19px;
  padding: 0;
  list-style: none;
}

.brenger-menu--wrapper .main-nav--items .sub-nav .nav-item--label {
  font-family: bariol, Arial, sans-serif;
}

.brenger-menu--wrapper .main-nav--items .hide-mobile {
  display: none;
}

@media (min-width: 768px) {
  .brenger-menu--wrapper .main-nav--items .hide-mobile {
    display: block;
  }
}

.brenger-menu--wrapper .main-nav--items .bottom-position {
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all .5s .25s;
  position: absolute;
  bottom: 15px;
  left: 15px;
  transform: translate3d(150px, 0, 0);
}

.main-nav--is-open .brenger-menu--wrapper .main-nav--items .bottom-position {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.brenger-menu--wrapper .main-nav--items .nav-item--has-image > span {
  height: .8em;
}

@media (min-width: 768px) {
  .brenger-menu--wrapper .main-nav--items .nav-item--has-image > span {
    height: .7em;
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub {
    z-index: 10;
    position: relative;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub > span {
    pointer-events: none;
    z-index: 5;
    position: relative;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub > span > span {
    pointer-events: none;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-image span {
    padding-right: 50px;
    position: relative;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-image .nav-item--image {
    z-index: 20;
    background-size: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: block;
    position: absolute;
    top: -.7em;
    right: 0;
  }
}

@media (min-width: 992px) and (min-width: 768px) {
  .brenger-menu--wrapper .main-nav--items .nav-item--has-image .nav-item--image {
    top: -.2em;
  }
}

@media (min-width: 992px) {
  .brenger-menu--wrapper .main-nav--items .sub-nav {
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    width: 170px;
    padding: 15px;
    transition: all .15s;
    position: absolute;
    top: 100%;
    right: -12px;
    transform: translate3d(0, 0, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav span {
    pointer-events: auto;
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav:after, .brenger-menu--wrapper .main-nav--items .sub-nav:before {
    content: "";
    background: #fff;
    position: absolute;
    right: 0;
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav:after {
    z-index: 0;
    border-radius: 8px 8px 0 0;
    width: 65px;
    height: 55px;
    top: -53px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav:before {
    z-index: 1;
    border-radius: 8px 0 8px 8px;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 7px 10px rgba(0, 0, 0, .1);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li {
    z-index: 2;
    padding: 5px 0;
    transition: all .25s .25s;
    display: block;
    position: relative;
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li a {
    pointer-events: auto;
    font-weight: normal;
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:nth-child(0) {
    transform: translate3d(0, 0, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:first-child {
    transform: translate3d(0, -20px, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:nth-child(2) {
    transform: translate3d(0, -40px, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:nth-child(3) {
    transform: translate3d(0, -60px, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:nth-child(4) {
    transform: translate3d(0, -80px, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav li:nth-child(5) {
    transform: translate3d(0, -100px, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav span {
    padding-right: 0;
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav--is-open .sub-nav {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  .brenger-menu--wrapper .main-nav--items .sub-nav--is-open .sub-nav li {
    transition-delay: 0s;
    transform: translate3d(0, 0, 0);
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--offset {
    margin-top: 15px;
  }

  .brenger-menu--wrapper .main-nav--items .color-grey .nav-item--label, .brenger-menu--wrapper .main-nav--items .color-grey a {
    color: #5f6e81;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub .sub-nav {
    pointer-events: none;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub.sub--is-open .sub-nav {
    opacity: 0;
    visibility: hidden;
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub.sub--is-open:hover .sub-nav, .brenger-menu--wrapper .main-nav--items .nav-item--has-sub:hover .sub-nav {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  .brenger-menu--wrapper .main-nav--items .nav-item--has-sub.sub--is-open:hover .sub-nav li, .brenger-menu--wrapper .main-nav--items .nav-item--has-sub:hover .sub-nav li {
    transition-delay: 0s;
    transform: translate3d(0, 0, 0);
  }
}

.brenger-menu--wrapper .main-nav--items a.btn {
  color: #fff;
}

.brenger-tr-widget--wrapper .input-el input {
  color: #223b58;
  font-size: inherit;
  background: #fff;
  border: none;
  width: 100%;
  margin: 0;
  padding: 1em 0;
  display: block;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .input-el input {
    padding: .75em 0;
  }
}

.brenger-tr-widget--wrapper .input-el input:focus {
  box-shadow: none;
  outline: none;
}

.brenger-tr-widget--wrapper .input-el input[disabled] {
  pointer-events: none;
  background: #f2f8ff;
}

.brenger-tr-widget--wrapper .input-el input::-ms-clear {
  display: none;
}

.brenger-tr-widget--wrapper .input-el input label {
  margin: 0;
}

@font-face {
  font-family: bariol;
  src: url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff2") format("woff2"), url("https://www.brenger.nl/assets/fonts/bariol_regular-webfont_sub.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.brenger-tr-widget--wrapper {
  all: unset;
  font-size: 18px;
}

.brenger-tr-widget--wrapper label, .brenger-tr-widget--wrapper div, .brenger-tr-widget--wrapper span {
  all: unset;
  font-family: bariol, Arial, sans-serif;
}

.brenger-tr-widget--wrapper div {
  display: block;
}

.brenger-tr-widget--wrapper strong, .brenger-tr-widget--wrapper .text--bold, .brenger-tr-widget--wrapper .text--bold i {
  font-weight: bold;
}

.brenger-tr-widget--wrapper * {
  box-sizing: border-box;
  font-family: bariol, Arial, sans-serif;
}

.brenger-tr-widget--wrapper .input-el--button {
  width: 100%;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .input-el--button {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .brenger-tr-widget--wrapper .input-el--button {
    margin-bottom: 0 !important;
  }
}

.brenger-tr-widget--wrapper .btn {
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  width: 100%;
  padding: 11px 15px;
  text-decoration: none;
  display: inline-block;
}

.brenger-tr-widget--wrapper .btn.widget {
  text-align: left;
  border-radius: 10px;
  padding: .85em;
  line-height: 1;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .btn.widget {
    width: auto;
    min-width: 240px;
  }
}

.brenger-tr-widget--wrapper .btn--secondary {
  color: #fff;
  background: #00d896;
  border-color: #00d896;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .btn--secondary:hover {
    background: #00bd84;
    border-color: #00bd84;
  }
}

.brenger-tr-widget--wrapper .btn--icon-right {
  position: relative;
}

.brenger-tr-widget--wrapper .btn--icon-right .icon-wrapper svg, .brenger-tr-widget--wrapper .btn--icon-right svg:not(.IconSmile) {
  width: 1.2em;
  height: 1.2em;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.brenger-tr-widget--wrapper .brenger-tr-widget--wrapper .btn--icon-right .icon-wrapper svg, .brenger-tr-widget--wrapper .brenger-tr-widget--wrapper .btn--icon-right svg:not(.IconSmile) {
  float: right;
  width: 1em;
  height: 1em;
  position: static;
  transform: none;
}

.brenger-tr-widget--wrapper .btn--icon-right {
  padding-right: 40px;
}

.brenger-tr-widget--wrapper .btn--icon-right .icon-wrapper svg, .brenger-tr-widget--wrapper .btn--icon-right svg {
  right: 10px;
}

.brenger-tr-widget--wrapper .spinning-smile {
  transform-origin: 30%;
  width: .8em;
  height: .8em;
  margin-right: .4em;
  animation: .33s linear infinite spin;
  display: inline-block;
  position: relative;
  left: .6em;
}

.brenger-tr-widget--wrapper .spinning-smile path {
  fill: #0073fa;
}

@keyframes spin {
  from {
    transform-origin: 30%;
    transform: rotate(0);
  }

  to {
    transform-origin: 30%;
    transform: rotate(360deg);
  }
}

.brenger-tr-widget--wrapper .IconArrow .arrow-shape {
  fill: #fff;
}

.brenger-tr-widget--wrapper ::-webkit-input-placeholder {
  color: #bcbcbc;
  -webkit-text-fill-color: #bcbcbc;
}

.brenger-tr-widget--wrapper ::placeholder {
  color: #bcbcbc;
  -webkit-text-fill-color: #bcbcbc;
}

.brenger-tr-widget--wrapper ::-ms-placeholder {
  color: #bcbcbc;
  -webkit-text-fill-color: #bcbcbc;
}

.brenger-tr-widget--wrapper ::placeholder {
  color: #bcbcbc;
  -webkit-text-fill-color: #bcbcbc;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--is-loading {
  border-radius: 8px;
}

.brenger-tr-widget--wrapper .input-el--has-value.input-el--is-valid input {
  border-color: #00d896;
}

.brenger-tr-widget--wrapper .input-el {
  display: block;
  position: relative;
}

.brenger-tr-widget--wrapper .input-el input {
  border-color: #0073fa;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .input-el {
    margin: 0;
  }
}

.brenger-tr-widget--wrapper .input-el input {
  box-shadow: none;
}

.location-input--wrapper .brenger-tr-widget--wrapper .input-el input {
  padding-left: 35px;
}

.brenger-tr-widget--wrapper .input-el--label {
  min-width: auto;
  font-weight: bold;
  display: block;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .input-el--label {
    min-width: auto;
  }
}

.brenger-tr-widget--wrapper .input-el--has-value .input-el--label, .brenger-tr-widget--wrapper .input-el--has-focus .input-el--label {
  display: none;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--selected input {
  font-weight: bold;
}

.brenger-tr-widget--wrapper .input-el--feedback {
  color: #e10000;
  z-index: 10;
  background-color: #fff;
  padding: 0;
  font-style: italic;
  line-height: 1.6;
  display: none;
  position: absolute;
  top: .8em;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .input-el--feedback {
    top: .7em;
  }
}

.brenger-tr-widget--wrapper .input-el--warning-icon {
  line-height: 1;
  display: none;
}

.input-el--has-feedback .brenger-tr-widget--wrapper .input-el--warning-icon {
  display: block;
}

.brenger-tr-widget--wrapper .input-el--has-feedback input {
  border-radius: 8px 8px 0 0;
}

.brenger-tr-widget--wrapper .input-el--has-feedback .input-el--feedback, .brenger-tr-widget--wrapper .input-el--has-feedback .input-el--warning-icon {
  display: block;
}

.brenger-tr-widget--wrapper .input-el--has-feedback .input-el--autocomplete--clear {
  display: none;
}

.brenger-tr-widget--wrapper .input-el--has-feedback input {
  color: #e10000;
  font-style: italic;
}

.brenger-tr-widget--wrapper .input-el--has-feedback.input-el--has-value .input-el--feedback {
  display: none;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .input-el--autocomplete {
    width: 50%;
    margin-left: .5em;
    margin-right: 15px;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete input {
  position: relative;
}

.brenger-tr-widget--wrapper .input-el--autocomplete label {
  margin-top: 0;
  margin-bottom: 0;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--icon-wrapper {
  width: 1.5em;
  height: 1em;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--icon-wrapper .IconSmile {
  margin-top: .2em;
  margin-left: -.2em;
  display: none;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--icon-wrapper .IconMarker {
  opacity: 0;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .input-el--autocomplete--icon-wrapper .IconMarker {
    opacity: 1;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete--is-loading .IconSmile {
  display: block;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--is-loading .IconMarker {
  display: none;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--clear {
  opacity: 0;
  cursor: pointer;
  margin-left: .5em;
  transition: opacity .25s;
}

.input-el--has-value .brenger-tr-widget--wrapper .input-el--autocomplete--clear {
  opacity: 1;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--clear svg {
  pointer-events: none;
  width: 12px;
  height: 12px;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--clear svg * {
  pointer-events: none;
  fill: #0073fa;
}

.brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete {
  z-index: 50;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  margin-top: 0;
  display: none;
  position: absolute;
  top: 3em;
  left: -.9em;
  right: -.9em;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .12);
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete {
    top: 3em;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete > div {
  background: #fff;
  padding: 15px 1.4em;
  display: block;
}

@media (min-width: 1025px) {
  .brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete > div {
    cursor: pointer;
    padding: 8px 15px 8px 35px;
  }

  .brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete > div:hover {
    background: #cde4ff;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete .autocomplete > div.selected {
  background: #eff5fe;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .input-el--autocomplete.location_autocomplete_general_pickup .autocomplete {
    left: -1.2em;
    right: -2.5em;
  }

  .brenger-tr-widget--wrapper .input-el--autocomplete.location_autocomplete_general_delivery {
    margin-left: 0;
  }

  .brenger-tr-widget--wrapper .input-el--autocomplete.location_autocomplete_general_delivery .autocomplete {
    left: -1.4em;
    right: -1.6em;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete .input-el--autocomplete--zero-result {
  z-index: 50;
  box-sizing: border-box;
  background-color: #fff;
  border: none;
  border-radius: 0 0 8px 8px;
  width: auto;
  margin-top: 0;
  padding: 15px 1.4em;
  display: none;
  position: absolute;
  top: 3em;
  left: -.9em;
  right: -.9em;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .12);
}

@media (min-width: 992px) {
  .location_autocomplete_general_pickup .brenger-tr-widget--wrapper .input-el--autocomplete .input-el--autocomplete--zero-result {
    left: -1.2em;
    right: -2.5em;
  }

  .location_autocomplete_general_delivery .brenger-tr-widget--wrapper .input-el--autocomplete .input-el--autocomplete--zero-result {
    left: -1.4em;
    right: -1.6em;
  }
}

.brenger-tr-widget--wrapper .input-el--autocomplete.input-el--has-value .autocomplete, .brenger-tr-widget--wrapper .input-el--autocomplete.input-el--show-zero .input-el--autocomplete--zero-result:not(:empty), .brenger-tr-widget--wrapper .input-el--autocomplete.input-el--has-focus.input-el--has-feedback .input-el--autocomplete--zero-result:not(:empty) {
  display: block;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--input-wrapper {
  align-items: center;
  display: flex;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--input-wrapper > label {
  flex-grow: 1;
  align-items: center;
  gap: 8px;
  display: flex;
}

.brenger-tr-widget--wrapper .input-el--autocomplete--input-wrapper > label input {
  flex-grow: 1;
}

.brenger-tr-widget--wrapper .brenger-tr-widget {
  color: #223b58;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 0;
  padding: .5em .9em .9em;
  display: block;
  position: relative;
  box-shadow: 0 8px 10px rgba(174, 191, 212, .2);
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget {
    justify-content: space-between;
    align-items: center;
    padding: .7em;
    display: flex;
  }
}

.brenger-tr-widget--wrapper .brenger-tr-widget svg.route {
  position: absolute;
  top: 1.9em;
  left: 1em;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget svg.route {
    display: none;
  }
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget .destination-fields {
    width: 100%;
    display: block;
  }
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget .destination-fields {
    justify-content: space-between;
    display: flex;
  }
}

.brenger-tr-widget--wrapper .brenger-tr-widget--divider {
  border-top: .5px solid #d8d8d8;
  margin-left: 26px;
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget--divider {
    border-top: none;
    border-left: .5px solid #d8d8d8;
    margin: 0 1.35em;
  }
}

.brenger-tr-widget--wrapper .brenger-tr-widget--header {
  text-align: center;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  display: block;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget--header {
    text-align: left;
    width: 100%;
    padding-top: 0;
    font-size: 20px;
  }
}

.theme--primary--light .brenger-tr-widget--wrapper .brenger-tr-widget--header, .hero--animated .brenger-tr-widget--wrapper .brenger-tr-widget--header {
  color: #0073fa;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .brenger-tr-widget .input-el--duplicate {
    display: none;
  }
}

.brenger-tr-widget--wrapper .brenger-autocomplete-widget {
  width: 100%;
}

@media (min-width: 768px) {
  .brenger-tr-widget--wrapper .brenger-autocomplete-widget {
    box-sizing: border-box;
  }
}

@media (min-width: 992px) {
  .brenger-tr-widget--wrapper .brenger-autocomplete-widget {
    padding-right: 30px;
  }
}

.brenger-tr-widget--wrapper .brenger-autocomplete-widget .input-el--autocomplete {
  width: 100%;
}

.brenger-tr-widget--wrapper .brenger-autocomplete-widget .brenger-autocomplete-overlay--mobile-header {
  display: none;
}

.brenger-tr-widget--wrapper.sticky-cta--wrapper {
  z-index: 500;
  text-align: center;
  padding: 0 15px;
  transition: transform .25s;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 110%, 0)scale(.9);
}

.show-sticky-cta .brenger-tr-widget--wrapper.sticky-cta--wrapper {
  transform: translate3d(0, 0, 0)scale(1);
}

.brenger-tr-widget--wrapper .btn--sticky-cta {
  width: auto;
  margin: 7.5px auto;
}

/*# sourceMappingURL=brenger-merged.css.map */
