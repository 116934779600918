.main-nav {
  &--toggle {
    position: absolute;
    right: math.div($grid-gutter-width, 2);
    top: 18px;
    z-index: 15;

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      display: none;
    }

    &-hamburger {
      position: relative;
      opacity: 1;
      transition: $default-transition-speed all;
      transform: rotate(0deg) translate3d(0, 0, 0) scale(1);
      background: $color--primary;
      width: 25px;
      height: 3px;
      margin: 0 0 4px 0;
      border-radius: 3px;

      .main-nav--is-open & {
        background: $color--white;

        &:nth-child(1) {
          transform: rotate(-45deg) translate3d(-2px, 4px, 0) scale(0.85);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg) translate3d(-6px, -8px, 0) scale(0.85);
        }
      }
    }
  }

  &--filler {
    $size: 320vw;
    $sizePortrait: 250vh;

    position: absolute;
    top: 0;
    right: 0;
    background: $color--primary;
    border-radius: 50%;
    .init & {
      transition: all 0.5s;
    }
    transform: translate3d($size, $size * -0.5, 0);
    height: $size;
    width: $size;
    transition-delay: 0.25s;
    z-index: 1;

    .main-nav--is-open & {
      transition-delay: 0s;
      transform: translate3d(math.div($size, 2), $size * -0.125, 0);
    }

    @media (orientation: portrait) {
      transform: translate3d($sizePortrait, $sizePortrait * -0.5, 0);
      height: $sizePortrait;
      width: $sizePortrait;

      .main-nav--is-open & {
        transform: translate3d(math.div($sizePortrait, 2), $sizePortrait * -0.125, 0);
      }
    }

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      display: none;
    }
  }

  &--items {
    padding-top: 75px;
    padding-bottom: 75px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $color--white;
    transition: all 0.5s;
    visibility: hidden;
    z-index: 10;

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      padding: 0;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      visibility: visible;
      transition: none;
      margin-top: -2px;
    }

    .main-nav--is-open & {
      visibility: visible;

      @include visible(true);
    }

    & > ul {
      position: relative;
      z-index: 5;
      list-style: none;
      margin: 0 !important;
      overflow: auto;
      overflow-x: hidden;
      padding: 0 math.div($grid-gutter-width, 2);
      height: 100%;
      width: 100%;

      .nav-item--has-sub > .nav-item--label {
        display: none;
      }

      @include media-breakpoint-up(#{$menu-breakpoint}) {
        height: 3.2em;
        width: auto;
        padding: 0;
        overflow: visible;
        float: right;
        display: flex;
        align-items: center;

        .nav-item--has-sub > .nav-item--label {
          display: block;
        }

        &.main-nav--items-mobile {
          display: none;
        }
      }
    }

    a {
      text-decoration: none;
      color: $color--white;
      font-weight: bold;

      @include media-breakpoint-up(lg) {
        &:hover {
          opacity: 0.75;
          text-decoration: none;
        }
      }
    }

    .bottom-position,
    .nav-item--label {
      font-size: 22px;
      font-weight: bold;
      color: $color--white;

      @include media-breakpoint-up(md) {
        color: $color--primary;
        font-size: inherit;
      }
    }

    li {
      padding: 0.2em 0;

      @include visible(false);

      transition: all 0.5s;
      transition-delay: 0.25s;
      transform: translate3d(100px, 0, 0);

      &.menu-index-1 {
        transform: translate3d(130px, 0, 0);
      }

      &.menu-index-2 {
        transform: translate3d(120px, 0, 0);
      }

      &.menu-index-3 {
        transform: translate3d(110px, 0, 0);
      }
      &.menu-index-4 {
        transform: translate3d(100px, 0, 0);
      }
      &.menu-index-5 {
        transform: translate3d(110px, 0, 0);
      }
      &.menu-index-6 {
        transform: translate3d(120px, 0, 0);
      }
      &.menu-index-7 {
        transform: translate3d(130px, 0, 0);
      }

      .main-nav--is-open & {
        @include visible(true);

        transition-delay: 0s;
        transform: translate3d(0, 0, 0);
      }

      @include media-breakpoint-up(#{$menu-breakpoint}) {
        display: inline-block;
        padding: 0;
        transition: none;
        transform: translate3d(0, 0, 0) !important;

        @include visible(true);

        margin-right: 20px;

        a {
          color: $color--primary;
        }

        & > a {
          display: inline-block;
        }
      }

      &.hidden {
        display: none;
      }
    }

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      ul > li:last-child {
        margin-right: 0;
      }
    }

    .sub-nav {
      list-style: none;
      padding: 0;
      margin-top: 19px;

      .nav-item--label {
        font-family: $font-family--normal;
      }
    }

    .hide-mobile {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .bottom-position {
      position: absolute;
      left: math.div($grid-gutter-width, 2);
      bottom: math.div($grid-gutter-width, 2);
      z-index: 10;
      transform: translate3d(150px, 0, 0);
      transition: all 0.5s;
      transition-delay: 0.25s;

      @include visible(false);

      .main-nav--is-open & {
        @include visible(true);

        transform: translate3d(0, 0, 0);
      }
    }

    .nav-item--has-image {
      & > span {
        height: 0.8em;

        @include media-breakpoint-up(md) {
          height: 0.7em;
          margin-top: 5px;
        }
      }
    }

    @include media-breakpoint-up(#{$menu-breakpoint}) {
      .nav-item--has-sub {
        position: relative;
        z-index: 10;

        & > span {
          pointer-events: none;
          position: relative;
          z-index: 5;

          & > span {
            pointer-events: none;
          }
        }
      }

      .nav-item--has-image {
        span {
          position: relative;
          padding-right: 50px;
        }

        .nav-item--image {
          display: block;
          width: 40px;
          height: 40px;
          background-size: cover;
          border-radius: 50%;
          position: absolute;
          top: -0.7em;
          right: 0;
          padding: 0;
          z-index: 20;

          @include media-breakpoint-up(md) {
            top: -0.2em;
          }
        }
      }

      .sub-nav {
        position: absolute;
        top: 100%;
        right: -12px;
        width: 170px;
        z-index: 10;

        span {
          pointer-events: auto;
        }

        @include visible(false);

        padding: math.div($grid-gutter-width, 2);
        transition: all 0.15s;
        transform: translate3d(0, 0, 0);

        &::after,
        &::before {
          content: '';
          position: absolute;
          right: 0;
          background: $color--white;
        }

        &::after {
          top: -53px;
          height: 55px;
          width: 65px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: $border-radius--default $border-radius--default 0 0;
          z-index: 0;
        }

        &::before {
          z-index: 1;
          top: 0;
          left: 0;
          bottom: 0;
          box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: $border-radius--default 0 $border-radius--default $border-radius--default;
        }

        li {
          transition: all 0.25s;
          transition-delay: 0.25s;
          position: relative;
          z-index: 2;
          display: block;
          padding: 5px 0;

          a {
            font-weight: normal;
            pointer-events: auto;
          }

          @for $i from 0 through 5 {
            &:nth-child(#{$i}) {
              transform: translate3d(0, (-20px * $i), 0);
            }
          }
        }

        span {
          padding-right: 0;
        }
      }

      .sub-nav--is-open {
        .sub-nav {
          transform: translate3d(0, 0, 0);

          @include visible(true);

          li {
            transition-delay: 0s;
            transform: translate3d(0, 0, 0);
          }
        }
      }

      .nav-item--offset {
        margin-top: math.div($grid-gutter-width, 2);
      }

      .color-grey {
        .nav-item--label,
        a {
          color: $color--grey;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .nav-item--has-sub {
        .sub-nav {
          pointer-events: none;
        }

        &.sub--is-open {
          .sub-nav {
            @include visible(false);
          }
        }

        &.sub--is-open:hover,
        &:hover {
          .sub-nav {
            pointer-events: auto;
            transform: translate3d(0, 0, 0);

            @include visible(true);

            li {
              transition-delay: 0s;
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }

    a.btn {
      color: $color--white;
    }
  }
}
